<template>
  <v-dialog
    max-width="500"
    :value="showDialog"
    :persistent="searching || updating"
    scrollable
    @click:outside="close"
  >
    <v-card>
      <v-card-title>
        {{ $t('Search for customer') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('You can search for name, phone, email or ssn') }}
      </v-card-subtitle>
      <v-sheet class="pb-5 px-6 grey-bottom-border-thin">
        <div class="font-weight-medium">
          {{ $t('Currently selected customer') }}
          <v-tooltip
            bottom
            color="black"
          >
            <template v-slot:activator="{ on, attrs}">
              <v-icon
                v-on="on"
                v-bind="attrs"
                right
                small
                class="text--primary"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>{{ $t('This is the customer that will associated with order when save is confirmed') }}</span>
          </v-tooltip>
        </div>
        <div>
          <v-list
            class="py-0"
            outlined
            rounded
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  {{ customerName(customer) }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="customer">
                  <div>
                    <v-chip
                      label
                      x-small
                      class="text-uppercase font-weight-light"
                    >
                      {{ $t('Phone') }}
                    </v-chip>
                    {{ customerPhone(customer) }}
                  </div>
                  <div>
                    <v-chip
                      label
                      x-small
                      class="text-uppercase font-weight-light"
                    >
                      {{ $t('Email') }}
                    </v-chip>
                    {{ customerEmail(customer) }}
                  </div>
                  <div>
                    <v-chip
                      label
                      x-small
                      class="text-uppercase font-weight-light"
                    >
                      {{ $t('SSN') }}
                    </v-chip>
                    {{ customerSSN(customer) }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action-text v-if="customer">
                <div
                  v-if="customer.active"
                  class="green--text"
                >
                  {{ $t('Active') }}
                </div>
                <div
                  v-else
                  class="red--text"
                >
                  {{ $t('Inactive') }}
                </div>
              </v-list-item-action-text>
            </v-list-item>
          </v-list>
        </div>
        <div class="pt-5">
          <v-btn
            block
            elevation="0"
            @click="setSelectedCustomerAsAnonymous"
          >
            {{ $t('Set as anonymous') }}
            <v-tooltip
              bottom
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  right
                  class="button-help-icon"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>
                {{ $t('When setting the customer or order owner as anonymous the customer will no longer be associated with the order') }}
              </span>
            </v-tooltip>
          </v-btn>
        </div>
        <div class="d-flex pt-5">
          <v-text-field
            dense
            outlined
            height="40"
            hide-details
            :loading="searching"
            :disabled="searching"
            v-model="searchValue"
            @keydown.enter="searchForCustomer"
          />
          <v-btn
            color="primary"
            elevation="0"
            height="40"
            class="ml-2"
            :loading="searching"
            @click="searchForCustomer"
          >
            {{ $t('Search') }}
          </v-btn>
        </div>
      </v-sheet>
      <v-card-text v-if="Array.isArray(customers) && customers.length > 0">
        <v-list
          max-height="300"
          class="px-0"
        >
          <v-list-item-group v-model="selectedCustomerIndex">
            <v-list-item
              v-for="(c, index) in customers"
              :key="c.uuid"
            >
              <v-list-item-avatar size="20">
                <v-icon
                  v-if="selectedCustomerIndex === index"
                  color="success"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon v-else>
                  mdi-circle-outline
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ customerName(c) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div>
                    <v-chip
                      label
                      x-small
                      class="text-uppercase font-weight-light"
                    >
                      {{ $t('Phone') }}
                    </v-chip>
                    {{ customerPhone(c) }}
                  </div>
                  <div>
                    <v-chip
                      label
                      x-small
                      class="text-uppercase font-weight-light"
                    >
                      {{ $t('Email') }}
                    </v-chip>
                    {{ customerEmail(c) }}
                  </div>
                  <div>
                    <v-chip
                      label
                      x-small
                      class="text-uppercase font-weight-light"
                    >
                      {{ $t('SSN') }}
                    </v-chip>
                    {{ customerSSN(c) }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action-text>
                <div
                  v-if="c.active"
                  class="green--text"
                >
                  {{ $t('Active') }}
                </div>
                <div
                  v-else
                  class="red--text"
                >
                  {{ $t('Inactive') }}
                </div>
              </v-list-item-action-text>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions :class="(Array.isArray(customers) && customers.length > 0) ? 'grey-top-border-thin' : ''">
        <v-spacer />
        <v-btn
          text
          color="error"
          :disabled="searching || updating"
          @click="close"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="searching"
          :loading="updating"
          @click="showConfirm = true"
        >
          {{ $t('Save') }}
        </v-btn>
        <v-slide-x-reverse-transition>
          <v-card
            v-if="showConfirm"
            class="d-flex justify-end white pa-2"
            flat
            style="position: absolute; z-index: 2; width: 100%; right: 8px; bottom: 0;"
          >
            <v-btn
              text
              color="error"
              :disabled="searching || updating"
              @click="showConfirm = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              :disabled="searching"
              :loading="updating"
              class="ml-2"
              @click="updateCustomer"
            >
              {{ $t('Confirm') }}
            </v-btn>
          </v-card>
        </v-slide-x-reverse-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
	name: "SimpleCustomerSearchDialog",
	props: {
		showDialog: {
			type: Boolean,
			default: false
		},
		currentCustomer: {
			type: Object,
			default: () => null
		}
	},
	emits: {
		close: null,
		updateCustomer: (customer) => {
			return customer
		}
	},
	data() {
		return {
			customer: null,
			searchValue: null,
			searching: false,
			updating: false,
			customers: [],
			selectedCustomerIndex: null,
			showConfirm: false
		}
	},
	mounted() {
		this.updating = false
		this.searching = false
		if(typeof this.currentCustomer !== 'undefined' && this.currentCustomer !== null) {
			try {
				this.customer = JSON.parse(JSON.stringify(this.currentCustomer))
			} catch (e) {
				// Do nothing
			}
		}
	},
	destroyed() {
		// Making sure all values are reset when dialog is closed
		this.customer = null
		this.searchValue = null
		this.searching = false
		this.updating = false
		this.selectedCustomerIndex = null
		this.showConfirm = false
	},
	watch: {
		selectedCustomerIndex(index) {
			if(typeof index === 'undefined' || index === null) {
				try {
					this.customer = JSON.parse(JSON.stringify(this.currentCustomer))
				} catch (e) {
					// Do nothing
				}
			} else {
				try {
					this.customer = this.customers[index]
				} catch (e) {
					// Do nothing
				}
			}
		}
	},
	computed: {
		selectedCustomer() {
			if(!Array.isArray(this.customers)) {
				return null
			}
			if(this.selectedCustomerIndex === null) {
				return null
			}
			if(this.customers.length < (this.selectedCustomerIndex + 1)) {
				return null
			}
			return this.customers[this.selectedCustomerIndex]
		},
	},
	methods: {
		customerPhone(customer) {
			if(typeof customer === 'undefined' || customer === null) {
				return null
			}
			if(typeof customer.phone === 'undefined' || customer.phone === null) {
				return this.$t('No phone set')
			}
			return customer.phone
		},
		customerEmail(customer) {
			if(typeof customer === 'undefined' || customer === null) {
				return null
			}
			if(typeof customer.email === 'undefined' || customer.email === null) {
				return this.$t('No email set')
			}
			return customer.email
		},
		customerSSN(customer) {
			if(typeof customer === 'undefined' || customer === null) {
				return null
			}
			if(typeof customer.ssn === 'undefined' || customer.ssn === null) {
				return this.$t('No SSN set')
			}
			return customer.ssn
		},
		customerName(customer) {
			if(typeof customer === 'undefined' || customer === null) {
				return this.$t('Anonymous')
			}
			if(typeof customer.address === 'undefined' || customer.address === null) {
				return this.$t('No name set')
			}
			if(typeof customer.address.name_line === 'undefined' || customer.address.name_line === null) {
				return this.$t('No name set')
			}
			return customer.address.name_line
		},
		setSelectedCustomerAsAnonymous() {
			this.customer = null
		},
		close() {
			if(this.searching === true) {
				return
			}
			if(this.updating === true) {
				return
			}
			this.$emit('close')
		},
		updateCustomer() {
			if(this.searching === true) {
				return
			}
			if(this.updating === true) {
				return
			}

			this.updating = true
			this.$emit('updateCustomer', this.customer)
		},
		searchForCustomer() {
			if(this.searching === true) {
				return
			}
			if(this.updating === true) {
				return
			}

			if(typeof this.searchValue === 'undefined' || this.searchValue === null) {
				return
			}

			this.searching = true
			this.$store.dispatch('searchCustomers', this.searchValue).then(results => {
				this.customers = results
			}).finally(() => {
				this.searching = false
			})
		}
	}
}
</script>

<style scoped>

</style>